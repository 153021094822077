<template>
  <div>
    <select-shop :loadingButton="loadingButton" v-on:getData="getTransactionDetails"></select-shop>
    <CCard class="shadow-sm">
      <div class="row" style="margin: 20px">
        <div class="col-md-10 col-9">
          <h2 class="font-weight-normal text-dark">
            {{ $t('taxInvoiceBill') }}
          </h2>
        </div>
        <CButton class="col-md-2 col-3 align-text-center" color="light">
          <a @click="$router.go(-1)">
            <CButton>
              <span><i class="fa fa-arrow-left mr-2"></i></span> {{ $t('back') }}
            </CButton>
          </a>
        </CButton>
      </div>
      <CCardBody>
        <div class="invoice-box" v-if="transactionData">
          <table>
            <tr class="top">
              <td colspan="6">
                <table>
                  <tr>
                    <td class="title">
                      <img :src="shop.remoteImagePath || 'https://via.placeholder.com/150'"
                        style="width: 150px; max-width: 150px;" />
                    </td>
                    <td style="text-align: right;">
                      <strong>ใบเสร็จรับเงิน / ใบกำกับภาษี</strong><br>
                      Invoice #: {{ transactionData.receiptNumber || '-' }}<br>
                      วันที่: {{ formattedDate }}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr class="information">
              <td colspan="6">
                <table>
                  <tr>
                    <td>
                      <strong>{{ company.name }}</strong><br>
                      {{ company.address }}<br>
                      เลขประจำตัวผู้เสียภาษี: {{ company.taxId }}<br>
                      <a :href="'tel:' + company.phone">{{ company.phone }}</a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>ลูกค้า</strong><br>
                      เลขประจำตัวผู้เสียภาษี: {{ transactionData.member.taxId || '-' }}<br>
                      ชื่อ : {{ transactionData.member.name || '-' }}<br>
                      ที่อยู่ : {{ transactionData.member.address || '-' }}<br>
                      เบอร์โทรศัพท์ : <a :href="'tel:' + (transactionData.member.phone || '-')">{{
      transactionData.member.phone || '-' }}</a>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr class="heading">
              <td style="text-align: left;">ลำดับ</td>
              <td style="text-align: left;">รายการ</td>
              <td>V/N</td>
              <td>จำนวน</td>
              <td>ราคา</td>
              <td>ยอดรวมทั้งหมด</td>
            </tr>
            <tr v-for="(item, index) in transactionData.receiptItems" :key="index" class="item">
              <td style="text-align: left;">{{ index + 1 }}</td>
              <td style="text-align: left;">
                {{ item.pluName || '-' }}
                <div v-if="item.orderedOptions && item.orderedOptions.length > 0">
                  <div v-for="(option, optionIndex) in item.orderedOptions" :key="optionIndex"
                    style="font-size: 12px; color: #555;">
                    - {{ option.optionItemName || '-' }}
                  </div>
                </div>
              </td>
              <td>{{ item.vatType || '-' }}</td>
              <td>{{ item.quantity || '-' }}</td>
              <td>{{ convertCurrency(item.price) || '-' }}</td>
              <td>{{ convertCurrency(item.totalAmount) || '-' }}</td>
            </tr>
            <tr class="total">
              <td colspan="5" style="text-align: right;">สินค้ามีภาษี</td>
              <td>{{ convertCurrency(transactionData.netTotalIncVAT) || '-' }}</td>
            </tr>
            <tr class="total">
              <td colspan="5" style="text-align: right;">สินค้าที่ไม่มีภาษี</td>
              <td>{{ convertCurrency(transactionData.netTotalNonVAT) || '-' }}</td>
            </tr>
            <tr class="total">
              <td colspan="5" style="text-align: right;">มูลค่าสินค้าที่ต้องเสีย Vat</td>
              <td>{{ convertCurrency(transactionData.totalTaxableValue) || '-' }}</td>
            </tr>
            <tr class="total">
              <td colspan="5" style="text-align: right;">ติดเป็นมูลค่าเพิ่ม 7%</td>
              <td>{{ convertCurrency(transactionData.vatAmount) || '-' }}</td>
            </tr>
            <tr class="total">
              <td colspan="5" style="text-align: right;">ยอดรวมทั้งสิ้น</td>
              <td>{{ convertCurrency(transactionData.grandTotal) || '-' }}</td>
            </tr>
          </table>
          <table class="footer-table">
            <tr>
              <td class="signature">
                ..........................................
              </td>
              <td class="signature">
                ..........................................
              </td>
            </tr>
            <tr>
              <td>
                วันที่
              </td>
              <td>
                ผู้รับเงิน
              </td>
            </tr>
          </table>
        </div>

        <div v-else>
          Loading...
        </div>
      </CCardBody>
      <div class="button-container">
        <CButton color="success" class="mr-2">
          <i class="fa fa-print mr-2"></i> {{ $t('printTaxInvoiceBill') }}
        </CButton>
        <CButton color="outline-success">
          <i class="fa fa-print mr-2"></i> {{ $t('printshortTaxInvoiceBill') }}
        </CButton>
      </div>
    </CCard>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import transaction from '@/model/report/transaction';
import util from '@/util/util';

export default {
  data() {
    return {
      loadingButton: false,
      transactionData: null,
      formattedDate: moment().format("DD MMM YYYY HH:mm"),
      company: {
        name: "Para",
        address: "170 Suanplu 1, Sathorn Road, Sathorn, Bangkok, 10120",
        taxId: "บางหว้า",
        phone: "0987654321",
      },
    };
  },
  computed: {
    ...mapGetters(["shops", "date", "users", "permissions"]),
    objectId() {
      return this.$route.params.receiptObjectId;
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId;
      },
      set(newValue) {
        return this.$store.dispatch("setShop", newValue);
      },
    },
    shop() {
      return this.shops.find((i) => this.shopObjectId === i.objectId);
    }
  },
  created() {
    this.getTransactionDetails();
  },
  methods: {
    async getTransactionDetails(page) {
      const objectId = this.objectId;
      const response = await transaction.salesTransactions(this.date.start, this.date.end, page);
      if (response && Array.isArray(response.data)) {
        this.transactionData = response.data.find(item => item.objectId === objectId) || null;
        this.loadingButton = true
      }
    },
    convertCurrency(value) {
      return util.convertCurrency(value);
    }
  },
};
</script>

<style>
body {
  /* font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif; */
  color: #555;
}

.invoice-box {
  width: 210mm;
  min-height: 297mm;
  padding: 20mm;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  font-size: 16px;
  line-height: 24px;
  color: #555;
  margin: auto;
}

.invoice-box table {
  width: 100%;
  line-height: inherit;
  text-align: left;
  border-collapse: collapse;
}

.invoice-box table td {
  padding: 5px;
  vertical-align: top;
}

.invoice-box table tr td:nth-child(2) {
  text-align: right;
}

.invoice-box table tr.top table td {
  padding-bottom: 20px;
}

.invoice-box table tr.top table td.title {
  font-size: 20px;
  line-height: 20px;
  color: #333;
}

.invoice-box table tr.information table td {
  padding-bottom: 40px;
}

.invoice-box table tr.heading td {
  background: #eee;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
  text-align: center;
}

.invoice-box table tr.details td {
  padding-bottom: 20px;
}

.invoice-box table tr.item td {
  border-bottom: 1px solid #eee;
  text-align: center;
}

.invoice-box table tr.item.last td {
  border-bottom: none;
}

.invoice-box table tr.total td {
  text-align: right;
  border-top: 1px solid #eee;
  font-weight: bold;
}

.footer-table {
  width: 100%;
  margin-top: 40px;
}

.footer-table td {
  text-align: center;
  padding-top: 40px;
}

.footer-table td.signature {
  width: 50%;
}

.button-container {
  display: flex;
  justify-content: center;
  margin: 40px;
}
</style>
